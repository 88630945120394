<script>
    import RoleGroupIndividualForm from "../../../components/forms/role-group-individual-form.vue";

    export default {
        components: {RoleGroupIndividualForm},

        created() {
            this.$store.dispatch('user/list', {with: 'roleGroups', sorting: { column: 'name', desc: false }})
            this.$store.dispatch('roleGroup/all', {with: ['roles', 'roleCategories', 'modules']})
        }
    }
</script>

<template>
    <role-group-individual-form></role-group-individual-form>
</template>

<style scoped>

</style>
