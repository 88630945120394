<script>
import {mapGetters} from "vuex";

export default {
    name: 'role-group-individual-form',

    emits: ['saved'],

    computed: {
        ...mapGetters({
            'loading': 'user/loading',
            'states': 'user/states',
            'errors': 'user/errors',
            roleGroups: 'roleGroup/all',
            users: 'user/list',
        }),
    },

    data: function () {
        return {
            selected: null,
            groups: null
        }
    },

    methods: {
        initGroups: function () {
            if (!this.selected || !this.selected.role_groups || !this.selected.role_groups.length) {
                this.groups = null
            }

            this.groups = this.selected.role_groups.map(roleGroup => roleGroup.id);
        },

        save: function () {
            const data = {id: this.selected.id, role_groups: this.groups}

            this.$store.dispatch('user/roleGroups', data).then(response => {
                if (this.selected) {
                    const selected = Object.assign({}, this.selected)
                    selected.role_groups = this.roleGroups.filter(group => response.role_groups.includes(group.id))
                    this.$store.dispatch('user/replaceItem', selected)

                }
            });
        },
    },

    watch: {
        selected: function () {
            this.initGroups()
        },
    }

}
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row">
                <div class="col-lg-2 border-white border-end">
                    <div class="row mb-4">
                        <div class="col-12">
                            <label class="form-label">{{ $tc('users.user', 1).ucFirst() }}</label>
                            <select
                                v-model="selected"
                                class="form-select"
                            >
                                <option :value="null">{{ $t('base.please_select').ucFirst() }}</option>
                                <option v-for="(user, index) in users" :key="index" :value="user">{{
                                        user.name
                                    }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <b-button :disabled="!selected" variant="primary" @click="save">{{ $t('base.save').ucFirst() }}</b-button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-10">
                    <div class="row">
                        <label class="form-label">{{
                                $tc('authorization::role_groups.role_group', 2).ucFirst()
                            }}</label>
                        <div v-for="(group, index) in roleGroups" :key="index"
                             class="col-12 col-sm-6 col-md-3 col-lg-2"
                        >
                            <div
                                class="d-flex justify-content-between align-items-center p-2 border bg-white rounded mb-2 me-3">
                                <p class="mb-0"><strong>{{
                                        group.translation_key ? $t(group.translation_key).ucFirst() : group.name
                                    }}</strong></p>
                                <div class="form-check d-inline-block ">
                                    <input v-model="groups" :value="group.id" class="form-check-input" type="checkbox"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
